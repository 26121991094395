import styled from "styled-components"

export const UserIcon = styled.div`
  grid-column: span 1;
  padding: 1rem;
  display: flex;
  img {
    max-width: 75px;
    margin: auto;
    max-height: 50px;
  }
`
