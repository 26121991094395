import styled from "styled-components"
import media from "@styles/media"
import theme from "@styles/theme"

export const YoutubeEmbed = styled.div`
  box-shadow: ${theme.shadow.default};
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
  }

  ${media.md`
    order: 1;
  `}
`
