import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import Div from "@components/elements/div"
import { graphql, Link } from "gatsby"
import Image from "@components/image"
import { processImages } from "@utils/process-images"
import { LogoCarousel } from "@components/common/logo-carousel"
import Span from "@components/elements/span"
import { YoutubeEmbed } from "@components/common/youtube-embed"
import { UserIcon } from "@components/pages/about/user-icon"
import { useTheme } from "styled-components"

const ClientsPage = ({ data }) => {
  const users = data.users.edges
  const images = processImages(data.images.edges)
  const { shadow } = useTheme()

  return (
    <Layout
      title={`Klipfolio Customers`}
      description={`Join hundreds of world-class organizations, from startups to global brands, who are leading the data revolution with Klipfolio. Find out which companies use Klipfolio here.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Flex gap="6rem">
        <Flex background="#edf3fd">
          <Grid
            container
            margin="8rem auto 4rem"
            alignItems="center"
            columns="1fr 1fr"
            columnsSm="1fr"
            gap="2rem"
          >
            <Flex gap="2rem">
              <Heading as="h1">Growing your business with data</Heading>
              <Paragraph>
                At Klipfolio, every decision we make is to help solve our
                customers’ data insight problems.
              </Paragraph>
            </Flex>
            <Flex>
              <Image
                noDimensions
                file={images["8c5b3f16-23d8-4908-a331-2889a1512802"]}
              />
            </Flex>
          </Grid>
        </Flex>
        <Div container width="100%">
          <Heading as="h2" center margin="0 0 4rem">
            The Klipfolio effect
          </Heading>
          <Grid
            columns="repeat(3, 1fr)"
            columnsMd="1fr 1fr"
            columnsSm="1fr"
            gap="3rem"
            margin="0 0 4rem"
          >
            <Link to="/blog/livedata-mobi-partner-spotlight">
              <Flex>
                <Paragraph fontWeight="600">
                  LiveData built and scaled
                </Paragraph>
                <Span
                  fontWeight="700"
                  color="#4b57c5"
                  fontSize="4rem"
                  lineHeight="1.2"
                >
                  250+
                </Span>
                <Paragraph fontWeight="600">
                  reports using Klipfolio&apos;s API
                </Paragraph>
              </Flex>
            </Link>
            <Link to="/blog/saves-time-by-switching-to-new-dashboard">
              <Flex>
                <Paragraph fontWeight="600">MagneticOne saves</Paragraph>
                <Span
                  fontWeight="700"
                  color="#4b57c5"
                  fontSize="4rem"
                  lineHeight="1.2"
                >
                  100s
                </Span>
                <Paragraph fontWeight="600">
                  of hours on report building
                </Paragraph>
              </Flex>
            </Link>
            <Link to="/blog/kpi-dashboards-improve-nps">
              <Flex>
                <Paragraph fontWeight="600">Gogoprint experienced an</Paragraph>
                <Span
                  fontWeight="700"
                  color="#4b57c5"
                  fontSize="4rem"
                  lineHeight="1.2"
                >
                  83%
                </Span>
                <Paragraph fontWeight="600">NPS rating improvement</Paragraph>
              </Flex>
            </Link>
          </Grid>
          <Grid columns="1fr 1fr" columnsMd="1fr" gap="3rem">
            <Flex gap="3rem">
              <Paragraph>
                We&apos;ve grown by over <b>300% since using Klipfolio</b>, and
                the decisions that led to this growth have come from data
                displayed on Klipfolio.
              </Paragraph>
              <Flex flexFlow="row" gap="1rem" alignItems="center">
                <Div background="#edf3fd" borderRadius="100%" overflow="hidden">
                  <Image
                    height={80}
                    width={80}
                    file={images["710b888f-5e05-4a2b-96e4-5a46c81e70ed"]}
                  />
                </Div>
                <Div>
                  <Paragraph>Marcus Taylor</Paragraph>
                  <Span>Founder and CEO, venture Harbour</Span>
                </Div>
              </Flex>
            </Flex>
            <Flex gap="3rem">
              <Paragraph>
                Our leadership team is making smarter decisions and were on
                target to <b>grow by 40—50% this year</b> thanks to our insights
                from Klipfolio.
              </Paragraph>
              <Flex flexFlow="row" gap="1rem" alignItems="center">
                <Div background="#edf3fd" borderRadius="100%" overflow="hidden">
                  <Image
                    height={80}
                    width={80}
                    file={images["496e87bb-03e3-4831-82ee-4ceab301498f"]}
                  />
                </Div>
                <Div>
                  <Paragraph>Brain Dainis</Paragraph>
                  <Span>Founder and CEO, Curotec</Span>
                </Div>
              </Flex>
            </Flex>
          </Grid>
        </Div>
        <Div
          container
          background="#edf3fd"
          width="100%"
          borderRadius="1rem"
          padding="4rem"
        >
          <Heading center as="h2" margin="0 0 1rem">
            Data-driven success stories
          </Heading>
          <Paragraph center fontWeight="600" margin="0 0 2rem">
            Read a few of our amazing customer case studies.
          </Paragraph>
          <Grid gap="2rem" columns="repeat(3, 1fr)" columnsMd="1fr">
            <Div
              background="white"
              borderRadius="1rem"
              overflow="hidden"
              shadow={shadow.fancy}
            >
              <Image
                noDimensions
                file={images["df1b27ee-6204-47ad-b012-25815be62944"]}
              />
              <Flex gap="1rem" padding="2rem">
                <Heading as="h4" lineHeight={1.5}>
                  Tantalus saves days of report building efforts with Klipfolio
                </Heading>
                <Paragraph
                  fontSize="1.1rem"
                  margin="0 0 0.5rem"
                  lineHeight={1.7}
                >
                  &quot;Our reports don’t just look good, it&apos;s more than
                  that. Klipfolio provides our executives with the metrics that
                  matter most while keeping our various teams motivated and
                  productive.&quot;
                </Paragraph>
                <Anchor
                  margin="auto 0 0"
                  arrow
                  link="/blog/save-time-executive-reporting"
                >
                  Read Case Study
                </Anchor>
              </Flex>
            </Div>
            <Div
              background="white"
              borderRadius="1rem"
              overflow="hidden"
              shadow={shadow.fancy}
            >
              <Image
                noDimensions
                file={images["7a712370-0caf-425e-a025-61f1bf3c3441"]}
              />
              <Flex gap="1rem" padding="2rem">
                <Heading as="h4" lineHeight={1.5}>
                  PayTabs embraces a data-driven strategy with KPI dashboards
                </Heading>
                <Paragraph
                  fontSize="1.1rem"
                  margin="0 0 0.5rem"
                  lineHeight={1.7}
                >
                  &quot;We love that Klipfolio is highly customizable, easy to
                  integrate, and user friendly. What’s great is you don&apos;t
                  need to be a developer to use Klipfolio, almost anyone can
                  build Klips.&quot;
                </Paragraph>
                <Anchor
                  margin="auto 0 0"
                  arrow
                  link="/blog/tracking-company-wide-kpis"
                >
                  Read Case Study
                </Anchor>
              </Flex>
            </Div>
            <Div
              background="white"
              borderRadius="1rem"
              overflow="hidden"
              shadow={shadow.fancy}
            >
              <Image
                noDimensions
                file={images["589eb980-fc74-4017-bc7c-648327014902"]}
              />
              <Flex gap="1rem" padding="2rem">
                <Heading as="h4" lineHeight={1.5}>
                  Klipfolio helps Think Biosolution fulfill over 90% of design
                  requirement
                </Heading>
                <Paragraph
                  fontSize="1.1rem"
                  margin="0 0 0.5rem"
                  lineHeight={1.7}
                >
                  &quot;Being able to monitor Klipfolio dashboards filled with
                  PowerMetrics and Klips has helped us to fulfill more than 90%
                  of our design requirements.&quot;
                </Paragraph>
                <Anchor
                  arrow
                  link="/blog/klipfolio-helps-fulfill-design-requirements"
                >
                  Read Case Study
                </Anchor>
              </Flex>
            </Div>
          </Grid>
          <Div margin="2rem 0 0" center>
            <Anchor link="/case-studies" arrow>
              See All Case Studies
            </Anchor>
          </Div>
        </Div>
        <Flex
          gap="1rem"
          maxWidth="100%"
          textContainer
          center
          margin="4rem auto"
        >
          <Heading as="h2">Don&apos;t take our word for it</Heading>
          <Paragraph>
            At Klipfolio, we are driven everyday by our amazing community of
            data driven heroes who are making an impact on their business.
          </Paragraph>
          <LogoCarousel padding="2rem 0 0" dark />
        </Flex>
        <Div
          container
          background="#edf3fd"
          width="100%"
          borderRadius="1rem"
          padding="4rem"
        >
          <YoutubeEmbed style={{ borderRadius: "1rem" }}>
            <iframe
              src="https://www.youtube.com/embed/ldx45Qrf_7E"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </YoutubeEmbed>
        </Div>
        <Div textContainer center margin="4rem auto 8rem">
          <Heading as="h2" fontSize="3rem" lineHeight="1.2" margin="0 0 2rem">
            Trusted by +50,000 data-driven leaders and their teams
          </Heading>
          <Paragraph fontSize="1.3rem">
            Smart businesses know that providing data insights drives their
            success!
          </Paragraph>
          <Grid
            margin="3rem 0 0"
            columns="repeat(auto-fill, minmax(150px, 1fr))"
          >
            {users.map(({ node: user }, i) => {
              return (
                <UserIcon key={`usericon-${i}`}>
                  <Image
                    objectFit="scale-down"
                    style={{ width: "100%" }}
                    file={user.company}
                  />
                </UserIcon>
              )
            })}
          </Grid>
        </Div>
      </Flex>
    </Layout>
  )
}

ClientsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ClientsPage

export const pageQuery = graphql`
  query ClientsPageQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "8c5b3f16-23d8-4908-a331-2889a1512802"
            "710b888f-5e05-4a2b-96e4-5a46c81e70ed"
            "496e87bb-03e3-4831-82ee-4ceab301498f"
            "df1b27ee-6204-47ad-b012-25815be62944"
            "7a712370-0caf-425e-a025-61f1bf3c3441"
            "589eb980-fc74-4017-bc7c-648327014902"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
    users: allOurUsers {
      edges {
        node {
          company {
            title
            cdn
            placeholder
            directus_id
          }
        }
      }
    }
  }
`
